import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import Icon from '@emcasa/ui-dom/components/Icon';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icon"
    }}>{`Icon`}</h1>
    <p>{`Icon.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<Icon name=\"cat\" color=\"gray\" mb={2} />\n<Icon name=\"feather\" size={30} color=\"pink\" />'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Icon
    }} mdxType="Playground">
  <Icon name="cat" color="gray" mb={2} mdxType="Icon" />
  <Icon name="feather" size={30} color="pink" mdxType="Icon" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      